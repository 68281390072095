import Grid from "@material-ui/core/Grid";
import {Route} from "react-router-dom";
import LogoSVG from "./logo.svg";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import React from "react";
import {useStyles, theme, MetaTags} from "./App";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import RobertBorowitzJPG from "./robert-borowitz.jpeg";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import FlagIcon from "@material-ui/icons/Flag";
import ListItemText from "@material-ui/core/ListItemText";
import WorkIcon from "@material-ui/icons/Work";
import CardActions from "@material-ui/core/CardActions";

function Home({header}){
    const classes = useStyles();
    return (
        <Grid container spacing={4} className={classes.massstabOnRight} >
            <MetaTags
                title={"Beratung, Aufmaß, Vertrieb und Baugutachten"}
                description={"Beratung, Aufmaß und Vertrieb von Fenstern, Türen und Toren und weiteren Bauelementen in Hamburg und Schleswig-Holstein. Ob Bauunternehmen, Vertriebler von Bauelementen oder Privatperson, mit meinen Dienstleistungen kann ich Sie bei Ihrem individuellen Projekt unterstützen."}
                path={""}
            />
            {/*<Grid item xs="12" className={classes.massstab} />*/}
            {header}
            {/*<Typography variant="h3" component="h3">Titel 3</Typography>
        <Typography variant="h4" component="h4">Titel 4</Typography>
        <Typography variant="h5" component="h5">Titel 5</Typography>
        <Typography variant="h6" component="h6">Titel 6</Typography>
        <Typography variant="subtitle2" component="h6">Subtitle 1</Typography>
        <Typography variant="subtitle1" component="h6">Subtitle 2</Typography>
        <Typography variant="body1" component="p">Body 1</Typography>
        <Typography variant="body2" component="p">Body 2</Typography>
        */}
            <Grid item xs="12" md="8" id="services">
                <Typography variant="h4" component="h4" color="secondary">Dienstleistungen</Typography>
                <Grid component={Paper} elevation={3} className={classes.service} id="beratungaufmass">
                    <Grid item>
                        <Typography variant="h4" component="h4">Beratung, Aufmaß und Vertrieb von Bauelementen</Typography>
                        {/*<Service1Stepper />*/}
                        <Typography variant="body1">
                            <ul>
                                <li>Produktberatung und Vertrieb von Fenstern, Türen, Toren und anderen Bauelementen vor Ort beim Kunden</li>
                                <li>Aufmaß und Festlegung von Bestellmaßen für den Auftraggebenden</li>
                                <li>Dokumentation der Aufmaße in Form professioneller Skizzen und Maßtabellen</li>
                                <li>Beratung zur Montage der jeweiligen Bauelemente</li>
                                <li>Koordination der Montage</li>
                            </ul>
                        </Typography>
                        <Button href='mailto:borowitz@bo-ftt-pro.de' className={classes.button} variant="contained" color="primary">
                            Kontaktieren
                        </Button>
                    </Grid>
                </Grid>

                <Grid component={Paper} elevation={3} className={classes.service} id="baugutachten">
                    <Grid item>
                        <Typography variant="h4" component="h4">Baugutachten</Typography>
                        <Typography variant="body1" component="p">Im Juni 2020 habe ich die Prüfung zum Bausachverständigen für Schäden an Gebäuden vor dem Prüfungsausschuss der DESAG (<a href="https://www.desag.de/">Deutsche Sachverständigen Gesellschaft</a>) erfolgreich abgelegt. Seit Juni 2020 bin ich Mitglied der DESAG und werde zukünftig Gutachten im Schwerpunkt Fenster und Türen anbieten.</Typography>
                        {/*<Button href='mailto:borowitz@bo-ftt-pro.de?subject="' className={classes.button} variant="contained" color="primary">
              Baugutachten anfordern
            </Button>*/}
                    </Grid>
                </Grid>

                <Typography variant="h4" component="h4" color="secondary">Ihr Vorteil</Typography>

                <Grid component={Paper} elevation={3} className={classes.service}>
                    <Grid item>
                        <Typography variant="h4" component="h4">Ich schaffe Ihnen Freiräume und Sicherheit</Typography>
                        <Typography variant="body1" component="p" align="left">Ob Bauunternehmen, Bauelementehändler, Tischlerei, Zimmerei, Hausverwaltung oder Privatperson, mit meinen Dienstleistungen kann ich Sie bei Ihrem individuellen Projekt unterstützen. Dabei bin ich Ihre Lösung für Wachstum trotz personeller Engpässe.</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs="12" md="4" id="aboutme">
                <Typography variant="h4" component="h4" color="secondary">Über mich</Typography>
                <MeinePerson />
            </Grid>
        </Grid>
    )
}

function MeinePerson() {
    const classes = useStyles();

    return (
        <Card className={classes.meinePerson} elevation={3}>
            <CardMedia
                className={classes.meinePersonMedia}
                image={RobertBorowitzJPG}
                title="Robert Borowitz"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Robert Borowitz
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Im Frühjahr 2020 habe ich mich mit meiner jahrelangen Berufserfahrung im Bauelementebereich, Fenster, Türen und Tore, entschlossen selbständig zu machen.
                </Typography>
                <List className={classes.root}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <FlagIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Sachverständiger für Schäden an Gebäuden" secondary="Weiterbildung im Juni 2020 erfolgreich abgeschlossen" />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <WorkIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Hass+Hatje Bauzentrum Rellingen" secondary="Abteilungsleiter für Bauelemente bis 2020" />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <WorkIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Baustoffhandel Joh. Wörmcke Uetersen" secondary="Vertrieb von Bauelementen bis 2008" />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <FlagIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`Weiterbildung zum staatl. gepr. Holztechniker und Tischlermeister`} secondary="1994 erfolgreich abgeschlossen" />
                    </ListItem>
                </List>
            </CardContent>
            <CardActions>
                <Button size="small" color="secondary" href="mailto:borowitz@bo-ftt-pro.de">
                    Kontaktieren Sie mich gerne
                </Button>
            </CardActions>
        </Card>
    );
}


export default Home;