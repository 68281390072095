import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import React from "react";
import {useStyles, theme, MetaTags} from "./App";

import Carousel from "react-material-ui-carousel";

import Project1Schiebetuer from "./projects/project1/schiebetuer.jpeg";
import Project1Haustuer1 from "./projects/project1/haustuer1.jpeg";
import Project1Rollladen1 from "./projects/project1/rollladen1.jpeg";
import Project1Terrassentuer from "./projects/project1/terrasentuer.jpeg";
import Project1Insektenschutz1 from "./projects/project1/insektenschutz1.jpeg";
import Project1TreppeBefore from "./projects/project1/treppe_before.jpeg";
import Project1Treppe1 from "./projects/project1/treppe1.jpeg";
import HBI from "./HBI_D_Logo_HKS_64.jpg";

function Suppliers({header}){
    const classes = useStyles();

    const spacing = 2;
    return (
        <Grid container spacing={4} className={classes.massstabOnRight} >
            {/*<Grid item xs="12" className={classes.massstab} />*/}
            <MetaTags
                title={"Lieferanten"}
                description={"Hier sehen Sie Lieferanten für Bauelemente, um Ihr Projekt umzusetzen: heideicke-fensterbau, hbi-fenster, solan, rolladen-growe"}
                path={"lieferanten/"}
            />
            {header}
            <Grid item xs="12" id="insight">
                <Divider variant="fullWidth" component="hr" />
                <Typography variant="h3" component="h3" color="secondary">Lieferanten</Typography>
                <Divider variant="fullWidth" component="hr" m={2}/>
                <Box mt={4}><Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Supplier
                            image={<a target="_blank" href="https://www.heidecke-fensterbau.de/"><img src={"https://www.heidecke-fensterbau.de/wp-content/uploads/2016/01/vektor_h.jpg"} style={{maxWidth:"100%", maxHeight:"100px"}} /></a>}
                            content={<Typography variant="h6" component="h5" color="secondary">Heidecke (Fenster und Türen)</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Supplier image={<a target="_blank" href="https://www.hbi-fenster.de/"><img src={HBI} style={{maxWidth:"100%", maxHeight:"100px"}} /></a>}
                            content={<Typography variant="h6" component="h5" color="secondary">HBI (Fenster und Türen)</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Supplier image={<a target="_blank" href="https://solan.de/"><img src={"https://solan.de/files/theme/images/logo.png"} style={{maxWidth:"100%", maxHeight:"100px"}} /></a>}
                            content={<Typography variant="h6" component="h5" color="secondary">Solan (Insekten- und Sonnenschutz)</Typography>}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Supplier image={<a target="_blank" href="https://www.rollladen-growe.de/"><img src={"https://www.rollladen-growe.de/fileadmin/templates/gfx/logo-growe-04.svg"} style={{maxWidth:"100%", height:"100px",backgroundColor:"#6EB63F"}} /></a>}
                            content={<Typography variant="h6" component="h5" color="secondary">Growe (Rollläden und Insektenschutz)</Typography>}
                        />
                    </Grid>
                </Grid></Box>
            </Grid>
        </Grid>
    )
}

function Supplier(props){
    const classes = useStyles();

    return (
        <Box p={2} component={Paper} className={classes.supplier}>
            <Grid container direction={"column"} spacing={2}>
                <Grid item>
                    {props.image}
                </Grid>
                <Grid item>
                    {props.content}
                </Grid>
            </Grid>
        </Box>
    );
}

function InsightCarousel(props)
{
    var items = [
        {
            name: "Insektenschutz-Schiebetür",
            description: "mit besonders transparentem Gewebe auf Maß gefertigt (Hersteller: Solan)",
            backgroundColor: theme.palette.secondary.light,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:true,
            image: Project1Schiebetuer
        },
        {
            name: "Kunststoffhaustür mit Seitenteil",
            description: "außen foliert in anthrazitgrau, Ornamentverglasung Satinato auf Maß gefertigt (Hersteller: Heidecke)",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Haustuer1
        },
        {
            name: "Vorbaurollladen Rundkastensystem",
            description: "elektrisch bedienbar, auf Maß gefertigt (Hersteller: Growe)",
            backgroundColor: theme.palette.secondary.light,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Rollladen1
        },
        {
            name: "Kunststoffterrassentür",
            description: "als 2-flg. Stulptür, außen anthrazitgrau foliert auf Maß gefertigt (Hersteller: Heidecke)",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Terrassentuer
        },
        {
            name: "Insektenschutz-Einhängerahmen",
            description: "mit besonders transparentem Gewebe im Kunststofffenster auf Maß gefertigt (Hersteller: Solan)",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Insektenschutz1
        },
        {
            name: "Treppe (vorher)",
            description: "Aufgabenstellung: alte Betonstufen sollen ein modernes Erscheinungsbild erhalten",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1TreppeBefore
        },
        {
            name: "Treppe (nachher)",
            description: "Die Treppenstufen wurden mit 20mm Eichenmassivholzplatten belegt. Die Oberfläche der Stufen wurde in geölter Ausführung hergestellt. Die Treppe hat mit den Blockstufen einen modernen Charakter erhalten.",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Treppe1
        }
    ]

    return (
        <Carousel autoPlay={false} interval={10000}>
            {
                items.map( item => {
                    return <CarouselItem item={item} />
                })
            }
        </Carousel>
    )
}

function CarouselItem({item})
{
    return (
        <Grid container component={Paper} rounded="5" style={{backgroundColor:item.backgroundColor,color:item.color,flexDirection:item.flipped?"row-reverse":"row"}}>
            <Grid item xs="12" style={{backgroundImage:"url("+item.image+")",backgroundRepeat:"no-repeat",backgroundPosition:"center",width:"100%", height:"700px"}} />
            <Grid item xs="12" style={{padding:item.padding}}>
                <Typography variant="h2" component="h2"><strong>{item.name}</strong></Typography>
                <Typography variant="h4" component="h4">{item.description}</Typography>
                <Button variant="contained" color={item.buttonColor}>
                    Kontakt
                </Button>
            </Grid>
        </Grid>
    )
}

export default Suppliers;