import Grid from "@material-ui/core/Grid";
import LogoSVG from "./logo.svg";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import React from "react";
import {useStyles, theme, MetaTags} from "./App";
import Carousel from "react-material-ui-carousel";
import Project1Schiebetuer from "./projects/project1/schiebetuer.jpeg";
import Project1Haustuer1 from "./projects/project1/haustuer1.jpeg";
import Project1Rollladen1 from "./projects/project1/rollladen1.jpeg";
import Project1Terrassentuer from "./projects/project1/terrasentuer.jpeg";
import Project1Insektenschutz1 from "./projects/project1/insektenschutz1.jpeg";
import Project1TreppeBefore from "./projects/project1/treppe_before.jpeg";
import Project1Treppe1 from "./projects/project1/treppe1.jpeg";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Container from "@material-ui/core/Container";

function Contact({header}){
    const classes = useStyles();
    return (
        <Grid container spacing={4} className={classes.massstabOnRight} >
            {/*<Grid item xs="12" className={classes.massstab} />*/}
            <MetaTags
                title={"Kontakt, Impressum und Datenschutz"}
                description={"borowitz FTT projekte GmbH - Kontakt, Imressum und Datenschutz - Geschäftsführer: Robert Borowitz"}
                path={"kontakt/"}
            />
            {header}
            <Grid item xs="12" id="insight">
                <Divider variant="fullWidth" component="hr" />
                <Typography variant="h3" component="h3" color="secondary">Impressum</Typography>
                <Divider variant="fullWidth" component="hr" />
                <Impressum />
                <Datenschutz />
            </Grid>
        </Grid>
    )
}

function Impressum() {
    const classes = useStyles();
    return (
        <div>
            <ExpansionPanel defaultExpanded={false} elevation={0} classes={{
                root: classes.impressum
            }}>
                <ExpansionPanelSummary
                    classes={{
                        'content': classes.impressumSummaryContent,
                        'root': classes.impressumSummary
                    }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h5" component="h4">Impressum</Typography>
                    <Typography variant="subtitle1" component="h5">Angaben gemäß § 5 TMG</Typography>
                    <Typography variant="body2" component="p">
                        borowitz FTT projekte GmbH<br/>
                        Dorfstraße 5<br/>
                        25560 Agethorst<br/>
                        <br/>
                        Geschäftsführer: Robert Borowitz
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Kontakt</Typography>
                    <Typography variant="body2" component="p">
                        Telefon: <Link color="textPrimary" href="tel:+4915229469447">+49 (0) 152 29469447</Link><br/>
                        E-Mail: <Link color="textPrimary" href="mailto:borowitz@bo-ftt-pro.de">borowitz@bo-ftt-pro.de</Link>
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Umsatzsteuer-ID</Typography>
                    <Typography variant="body2" component="p">
                        Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br/>
                        DE334448344
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Handelsregistereintrag</Typography>
                    <Typography variant="body2" component="p">
                        Amtsgericht Pinneberg, HRB 15218 PI
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5"><i>Um weiterzulesen, bitte ausklappen!</i></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        'root': classes.impressumDetails
                    }}
                >
                    <Typography variant="subtitle1" component="h5">EU-Streitschlichtung</Typography>
                    <Typography variant="body2" component="p">
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br/>
                        <Link color="textPrimary" href="https://ec.europa.eu/consumers/odr" color="text">https://ec.europa.eu/consumers/odr</Link>.<br/>
                        Unsere E-Mail-Adresse finden Sie oben im Impressum.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Verbraucherstreitbeilegung/Universalschlichtungsstelle</Typography>
                    <Typography variant="body2" component="p">
                        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Haftung für Inhalte</Typography>
                    <Typography variant="body2" component="p">
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                        verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                        forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/>
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Haftung für Links</Typography>
                    <Typography variant="body2" component="p">
                        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                        Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                        verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                        Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                        waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br/>
                        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                        umgehend entfernen.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Urheberrecht</Typography>
                    <Typography variant="body2" component="p">
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                        Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                        Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br/>
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                        Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Quelle:</Typography>
                    <Link color="textPrimary" href="https://www.e-recht24.de">e-recht24.de</Link>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

function Datenschutz() {
    const classes = useStyles();
    return (
        <div>
            <ExpansionPanel defaultExpanded={false} elevation={0} classes={{
                root: classes.datenschutz
            }}>
                <ExpansionPanelSummary
                    classes={{
                        'content': classes.datenschutzSummaryContent,
                        'root': classes.datenschutzSummary
                    }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h6">Datenschutzerklärung</Typography>
                    <Typography variant="subtitle1" component="h5"><strong>1. Datenschutz auf einen Blick</strong></Typography>
                    <Typography variant="subtitle1" component="h5">Allgemeine Hinweise</Typography>
                    <Typography variant="body2" component="p">
                        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                        passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                        persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
                        Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Datenerfassung auf dieser Website</Typography>
                    <Typography variant="body2" component="p">
                        <strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong><br/>
                        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                        können Sie dem Impressum dieser Website entnehmen.<br/>
                        <strong>Wie erfassen wir Ihre Daten?</strong><br/>
                        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
                        Daten handeln, die Sie in ein Kontaktformular eingeben.<br/>
                        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-
                        Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
                        des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.<br/>
                        <strong>Wofür nutzen wir Ihre Daten?</strong><br/>
                        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                        Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.<br/>
                        <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong><br/>
                        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                        gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                        Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                        können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
                        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
                        Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum
                        angegebenen Adresse an uns wenden.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5"><i>Um weiterzulesen, bitte ausklappen!</i></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{
                        'root': classes.datenschutzDetails
                    }}
                >
                    <Typography variant="subtitle1" component="h5"><strong>2. Hosting und Content Delivery Networks (CDN)</strong></Typography>
                    <Typography variant="subtitle1" component="h5">Externes Hosting</Typography>
                    <Typography variant="body2" component="p">
                        Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die
                        auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
                        a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
                        Namen, Webseitenzugriffe und sonstige Daten, die über eine Website generiert werden, handeln.<br/>
                        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
                        bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
                        Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).<br/>
                        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
                        erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5"><strong>3. Allgemeine Hinweise und Pflichtinformationen</strong></Typography>
                    <Typography variant="subtitle1" component="h5">Datenschutz</Typography>
                    <Typography variant="body2" component="p">
                        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                        personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                        dieser Datenschutzerklärung.<br/>
                        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                        Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
                        und zu welchem Zweck das geschieht.<br/>
                        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
                        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                        möglich.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Hinweis zur verantwortlichen Stelle</Typography>
                    <Typography variant="body2" component="p">
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br/>
                        Robert Borowitz, siehe dazu Anschrift im Impressum.<br/>
                        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
                        die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
                        entscheidet.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Widerruf Ihrer Einwilligung zur Datenverarbeitung</Typography>
                    <Typography variant="body2" component="p">
                        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
                        bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
                        Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</Typography>
                    <Typography variant="body2" component="p">
                        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
                        ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
                        SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
                        WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
                        PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
                        ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
                        WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
                        SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
                        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
                        VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                        RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).<br/>
                        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
                        SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
                        BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
                        EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
                        VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
                        ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
                        NACH ART. 21 ABS. 2 DSGVO).
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Beschwerderecht bei der zuständigen Aufsichtsbehörde</Typography>
                    <Typography variant="body2" component="p">
                        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                        Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres gewöhnlichen Aufenthalts, Ihres Arbeitsplatzes
                        oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
                        verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Recht auf Datenübertragbarkeit</Typography>
                    <Typography variant="body2" component="p">
                        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                        automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                        aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                        verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">SSL- bzw. TLS-Verschlüsselung</Typography>
                    <Typography variant="body2" component="p">
                        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
                        Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-
                        Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
                        „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.<br/>
                        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
                        von Dritten mitgelesen werden.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Auskunft, Löschung und Berichtigung</Typography>
                    <Typography variant="body2" component="p">
                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                        Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
                        Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
                        zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
                        angegebenen Adresse an uns wenden.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Recht auf Einschränkung der Verarbeitung</Typography>
                    <Typography variant="body2" component="p">
                        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                        Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
                        auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
                        <ul>
                            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
                                in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
                                statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
                            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                                Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                                Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                                Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                                überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                                zu verlangen.</li>
                        </ul>
                        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
                        Ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
                        Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
                        juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
                        eines Mitgliedstaats verarbeitet werden.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">4. Datenerfassung auf dieser Website</Typography>
                    <Typography variant="body2" component="p">
                        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-
                        Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                        <ul>
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>IP-Adresse</li>
                        </ul>
                        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.<br/>
                        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
                        ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website –
                        hierzu müssen die Server-Log-Files erfasst werden.
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Anfrage per E-Mail und Telefon</Typography>
                    <Typography variant="body2" component="p">
                        Wenn Sie uns per E-Mail und Telefon kontaktieren, wird Ihre Anfrage inklusive aller daraus
                        hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
                        bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br/>
                        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
                        der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
                        effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.<br/>
                        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
                        auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
                        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
                        insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.<br/>
                    </Typography><br/>
                    <Typography variant="subtitle1" component="h5">Quelle:</Typography>
                    <Link href="https://www.e-recht24.de">e-recht24.de</Link>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}

export default Contact;