import Grid from "@material-ui/core/Grid";
import LogoSVG from "./logo.svg";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import React from "react";
import {useStyles, theme, MetaTags} from "./App";
import Carousel from "react-material-ui-carousel";
import Project1Schiebetuer from "./projects/project1/schiebetuer.jpeg";
import Project1Haustuer1 from "./projects/project1/haustuer1.jpeg";
import Project1Rollladen1 from "./projects/project1/rollladen1.jpeg";
import Project1Terrassentuer from "./projects/project1/terrasentuer.jpeg";
import Project1Insektenschutz1 from "./projects/project1/insektenschutz1.jpeg";
import Project1TreppeBefore from "./projects/project1/treppe_before.jpeg";
import Project1Treppe1 from "./projects/project1/treppe1.jpeg";

function Projects({header}){
    const classes = useStyles();
    return (
        <Grid container spacing={4} className={classes.massstabOnRight} >
            <MetaTags
                title={"Projekte"}
                description={"Hier erhalten Sie einen Einblick einige meiner bisher durchgeführten Projekte."}
                path={"projekte/"}
            />
            {/*<Grid item xs="12" className={classes.massstab} />*/}
            {header}
            <Grid item xs="12" id="insight">
                <Divider variant="fullWidth" component="hr" />
                <Typography variant="h4" component="h4" color="secondary">Einblick in meine Projekte</Typography>
                <Typography variant="h5" component="h4" color="secondary">Modernisierung Einfamilienhaus in Itzehoe (März 2021)</Typography>
                <Typography variant="h6" component="h6" color="secondary">Beratung, Aufmaß, Lieferung und Montageplanung</Typography>
                <InsightCarousel />
            </Grid>
        </Grid>
    )
}

function InsightCarousel(props)
{
    var items = [
        {
            name: "Insektenschutz-Schiebetür",
            description: "mit besonders transparentem Gewebe auf Maß gefertigt (Hersteller: Solan)",
            backgroundColor: theme.palette.secondary.light,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:true,
            image: Project1Schiebetuer
        },
        {
            name: "Kunststoffhaustür mit Seitenteil",
            description: "außen foliert in anthrazitgrau, Ornamentverglasung Satinato auf Maß gefertigt (Hersteller: Heidecke)",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Haustuer1
        },
        {
            name: "Vorbaurollladen Rundkastensystem",
            description: "elektrisch bedienbar, auf Maß gefertigt (Hersteller: Growe)",
            backgroundColor: theme.palette.secondary.light,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Rollladen1
        },
        {
            name: "Kunststoffterrassentür",
            description: "als 2-flg. Stulptür, außen anthrazitgrau foliert auf Maß gefertigt (Hersteller: Heidecke)",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Terrassentuer
        },
        {
            name: "Insektenschutz-Einhängerahmen",
            description: "mit besonders transparentem Gewebe im Kunststofffenster auf Maß gefertigt (Hersteller: Solan)",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Insektenschutz1
        },
        {
            name: "Treppe (vorher)",
            description: "Aufgabenstellung: alte Betonstufen sollen ein modernes Erscheinungsbild erhalten",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1TreppeBefore
        },
        {
            name: "Treppe (nachher)",
            description: "Die Treppenstufen wurden mit 20mm Eichenmassivholzplatten belegt. Die Oberfläche der Stufen wurde in geölter Ausführung hergestellt. Die Treppe hat mit den Blockstufen einen modernen Charakter erhalten.",
            backgroundColor: theme.palette.secondary.dark,
            buttonColor: "secondary",
            color:"white",
            padding:theme.spacing(2),
            flipped:false,
            image: Project1Treppe1
        }
    ]

    return (
        <Carousel autoPlay={true} interval={10000}>
            {
                items.map( item => {
                    return <CarouselItem item={item} />
                })
            }
        </Carousel>
    )
}

function CarouselItem({item})
{
    return (
        <Grid container component={Paper} rounded="5" style={{backgroundColor:item.backgroundColor,color:item.color,flexDirection:item.flipped?"row-reverse":"row"}}>
            <Grid item xs="12" style={{backgroundImage:"url("+item.image+")",backgroundRepeat:"no-repeat",backgroundPosition:"center",width:"100%", height:"700px"}} />
            <Grid item xs="12" style={{padding:item.padding}}>
                <Typography variant="h3" component="h3" style={{wordBreak:"normal",hyphens:"auto"}}><strong>{item.name}</strong></Typography>
                <Typography variant="h4" component="h4" style={{wordBreak:"normal",hyphens:"auto"}}>{item.description}</Typography>
                <Button variant="contained" color={item.buttonColor}>
                    Kontakt
                </Button>
            </Grid>
        </Grid>
    )
}

export default Projects;